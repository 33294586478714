import axios from "axios"
import {deleteUserRequest, getUserRequest, postUserRequest, putUserRequest,} from "@/store/requests/userRequests";
import {
  deleteImageDataRequest,
  getImageDataRequest,
  getImagesDataRequest,
  loadImagePageRequest,
  patchImageDataRequest
} from "@/store/requests/imageDataRequests";
import {loadDefaultValuesRequest, putDefaultValueRequest,} from "@/store/requests/defaultValueRequests";
import {
  ADD_META_DATA_FIELD,
  DELETE_IMAGE_AND_DATA, DELETE_META_DATA_FIELD,
  DEMOTE_USER,
  DOWNLOAD_RESULTS,
  GET_DOCUMENT,
  GET_IMAGES_DATA,
  LIST_DEFAULT_VALUES,
  LIST_DOCUMENTS,
  LIST_META_DATA_FIELDS,
  LIST_TRANSCRIPT_FIELDS,
  LOAD_IMAGE_DATA,
  LOAD_IMAGES,
  LOAD_WHITELIST,
  LOGIN,
  PATCH_IMAGE_DATA,
  PATCH_TRANSCRIPT,
  PROMOTE_USER,
  REGISTER,
  REMOVE_USER,
  SEARCH_COLLECTION,
  SEARCH_IMAGE,
  SET_DEFAULT_VALUE,
  SWITCH_TO_SEARCH,
  WHITELIST_USER,
} from '@/store/operations'

import {
  downloadResults,
  getDocument,
  listDocuments,
  listTranscriptFieldsRequest,
  patchTranscriptRequest,
  searchCollection,
} from "@/store/requests/collectionRequests";
import {
  addGlobalMetaDataFieldName,
  deleteGlobalMetaDataFieldName,
  listGlobalMetaDataFieldNames
} from "@/store/requests/globalMetaDataRequests";

const actions = {
  [DOWNLOAD_RESULTS](context, config) {
    axios(downloadResults(config)).then(response => context.commit(DOWNLOAD_RESULTS, response.data))
  },
  [SEARCH_COLLECTION](context, config) {
    axios(searchCollection(config)).then(response => context.commit(SEARCH_COLLECTION, response.data))
  },
  [LIST_DOCUMENTS](context) {
    axios(listDocuments()).then(response => context.commit(LIST_DOCUMENTS, response.data))
  },
  [GET_DOCUMENT](context, {id}) {
    axios(getDocument(id)).then(response => context.commit(GET_DOCUMENT, response.data))
  },
  [PATCH_TRANSCRIPT](context, patch) {
    axios(patchTranscriptRequest(patch))
        .then(response => context.commit(PATCH_TRANSCRIPT,
            response.data
        ))
  },
  [LIST_TRANSCRIPT_FIELDS](context) {
    axios(listTranscriptFieldsRequest())
        .then(response => context.commit(LIST_TRANSCRIPT_FIELDS,
            response.data
        ))
  },
  [LIST_META_DATA_FIELDS](context) {
    axios(listGlobalMetaDataFieldNames()).then(response => context.commit(LIST_META_DATA_FIELDS, response.data))
    context.commit(LIST_META_DATA_FIELDS, [])
  },
  [ADD_META_DATA_FIELD](context, {name}) {
    axios(addGlobalMetaDataFieldName(name)).then(response => context.commit(ADD_META_DATA_FIELD, response.data))
  },
  [DELETE_META_DATA_FIELD](context, {name}) {
    axios(deleteGlobalMetaDataFieldName(name)).then(response => context.commit(DELETE_META_DATA_FIELD, response.data))
  },

  [LOAD_IMAGES](context) {
    axios(loadImagePageRequest())
        .then(response => context.commit(
            LOAD_IMAGES,
            response.data
        ))
  },
  [LOAD_IMAGE_DATA](context, image) {
    axios(getImageDataRequest(image))
        .then(response => {
          context.commit(LOAD_IMAGE_DATA,
              {
                ...image,
                ...response.data
              }
          )
        })
        .catch(() => {
          context.commit(LOAD_IMAGE_DATA,
              {
                ...image,
              })
        })
  },
  [LIST_DEFAULT_VALUES](context) {
    axios(loadDefaultValuesRequest())
        .then(response => context.commit(LIST_DEFAULT_VALUES,
            response.data
        ))
  },
  [PATCH_IMAGE_DATA](context, patch) {
    axios(patchImageDataRequest(patch))
        .then(() => context.commit(PATCH_IMAGE_DATA,
            patch
        ))
  },
  [DELETE_IMAGE_AND_DATA](context, image) {
    axios(deleteImageDataRequest(image))
        .then(() => {
          context.commit(DELETE_IMAGE_AND_DATA, {
            image
          })
        })
  },
  [SET_DEFAULT_VALUE](context, update) {
    axios(putDefaultValueRequest(update))
        .then(() => {
          context.commit(SET_DEFAULT_VALUE, {
            ...update
          })
        })
        .catch(err => context.commit(SET_DEFAULT_VALUE,
            {error: err})
        )
  },
  [GET_IMAGES_DATA](context) {
    return axios(getImagesDataRequest())
        .then(response => context.commit(GET_IMAGES_DATA,
            response.data
        ))
  },
  [SEARCH_IMAGE](context, query) {
    actions[GET_IMAGES_DATA](context)
        .finally(() => {
          context.commit(SWITCH_TO_SEARCH)
          context.commit(SEARCH_IMAGE, query)
        })
  },
  [REGISTER](context, credentials) {
    return axios(postUserRequest('', credentials))
        .then(response => context.commit(
            LOGIN,
            response.data
        ))
  },
  [LOGIN](context, credentials) {
    return axios(postUserRequest('', credentials))
        .then(response => context.commit(
            LOGIN,
            response.data
        ))
  },
  [LOAD_WHITELIST](context) {
    return axios(getUserRequest())
        .then(response => context.commit(
            LOAD_WHITELIST,
            response.data
        ))
  },
  [REMOVE_USER](context, email) {
    return axios(deleteUserRequest(email))
        .then(() => context.commit(
            REMOVE_USER,
            email
        ))
  },
  [WHITELIST_USER](context, email) {
    return axios(putUserRequest(email))
        .then(() => context.commit(
            WHITELIST_USER,
            email
        ))
  },
  [PROMOTE_USER](context, user) {
    return axios(putUserRequest(user, 'promote'))
        .then(result => context.commit(
            PROMOTE_USER,
            result.data
        ))
  },
  [DEMOTE_USER](context, user) {
    return axios(putUserRequest(user, 'demote'))
        .then(result => context.commit(
            DEMOTE_USER,
            result.data
        ))
  }
}
export default actions
