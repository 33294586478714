// 1) determine case
// 2) identify operands
// 3) formulate test case

import { isNumeric } from "../../../lib/getVariableType"

const tests = {
  isInList: list => value => list.includes(value),
  isOutsideOfRange: range => value => value < range.low || range.high < value,
  isWithinRange: range => value => range.low <= value && value <= range.high,
  lessThanOrEqual: operand => value => value <= operand,
  lessThan: operand => value => value < operand,
  greaterThanOrEqual: operand => value => operand <= value,
  greaterThan: operand => value => operand < value,
}
function getOperation(pattern) {
  /*
    list:
      n, m, o, p
    excluded from range:
      <n m>
    within in range
      >n m<
    lessThanOrEqual:
      <=n
    lessThan:
      <n
    greaterThanOrEqual:
      m=>
    greaterThan:
      m>
   */
  pattern = pattern.trim()
  let operation
  if (isListOfNumbers(pattern)) {
    const list = getList(pattern)
    operation = tests.isInList(list)
  } else if (isInclusiveRange(pattern)) {
    const range = getRange(pattern)
    operation = tests.isWithinRange(range)
  } else if (isExclusiveRange(pattern)) {
    const range = getRange(pattern)
    operation = tests.isOutsideOfRange(range)
  } else if (pattern.startsWith('<=')) {
    operation = tests.lessThanOrEqual(getOperandRight(pattern, '<='))
  } else if (pattern.startsWith('<')) {
    operation = tests.lessThan(getOperandRight(pattern, '<'))
  } else if (pattern.endsWith('=>')) {
    operation = tests.greaterThanOrEqual(getOperandLeft(pattern, '=>'))
  } else if (pattern.endsWith('>')) {
    operation = tests.greaterThan(getOperandLeft(pattern, '>'))
  }
  return operation
}
function getOperandRight(value, operator) {
  return parseFloat(value.substring(operator.length).trim())
}
function getOperandLeft(value, operator) {
  return parseFloat(value.substring(0, value.length - operator.length).trim())
}
function getList(value) {
  if (value.startsWith('[')) value = value.substring(1)
  if (value.endsWith(']')) value = value.substring(0, value.length - 1)
  return value
    .split(',')
    .map(item => item.trim())
    .filter(item => item)
    .map(item => parseFloat(item))
}
function getRange(value) {
  const range = value
    .substring(1, value.length - 1)
    .split(' ')
    .filter(item => item)
    .map(item => parseFloat(item))
  return {
    low: range[0],
    high: range[1]
  }
}
function isListOfNumbers(value) {
  let pass = true
  value.split(',')
    .map(item => item.trim())
    .forEach(item => pass &= isNumeric(item))
  return pass
}
function isExclusiveRange(value) {
 return value.startsWith('<') && value.endsWith('>')
}
function isInclusiveRange(value) {
  return value.startsWith('>') && value.endsWith('<')
}

export default function (list, query, attributeNames) {
  return list.filter(item => {
    let pass = false
    attributeNames.forEach(name => {
      const value = item[name]
      if (value) {
        const pattern = query[name]
        if (pattern) {
          const test = getOperation(pattern)
          pass |= test(value)
        }
      }
    })
    return pass
  })
}
