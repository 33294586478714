export const isString = v => { return typeof v === 'string' || v instanceof String }
export const isNumber = v => { return typeof v === 'number' || v instanceof Number }
export const isBoolean = v => { return typeof v === 'boolean' || v instanceof Boolean }
export const isArray = v => { return Array.isArray(v) }
export const isRegExp = v => { return v instanceof RegExp }
export const isFunction = v => { return typeof v === 'function' || v instanceof Function }
export const isObject = (v, strict) => {
  let assessment = typeof v === 'object'
  if (strict) {
    assessment = assessment && !isString(v)
    assessment = assessment && !isNumber(v)
    assessment = assessment && !isBoolean(v)
    assessment = assessment && !isArray(v)
    assessment = assessment && !isRegExp(v)
    assessment = assessment && !isFunction(v)
  }
  return assessment
}
export const isArrayOfStrings = v => {
  let pass = isArray(v)
  if (pass) {
    v.forEach(item => pass = pass && isString(item))
  }
  return pass
}
export const isArrayOfNumbers = v => {
  let pass = isArray(v)
  if (pass) {
    v.forEach(item => pass = pass && isNumber(item))
  }
  return pass
}
export const isNumeric = stringOrNumber => !isNaN(stringOrNumber)
export default function getVariableType(v) {
  let type = isString(v) ? 'String' : undefined
  if (!type) type = isNumber(v) ? 'Number.vue' : undefined
  if (!type) type = isBoolean(v) ? 'Boolean' : undefined
  if (!type) type = isFunction(v) ? 'Function' : undefined
  if (!type) type = isRegExp(v) ? 'RegExp' : undefined
  if (!type) type = isArray(v) ? 'Array' : undefined
  if (!type) type = isObject(v) ? 'Object' : undefined
  return type
}
