<template>
  <div id="root">
    <div class="left">
      <Navigation @select="handleNavigationSelect" />
    </div>
    <div
        :style="backgroundStyle"
        class="right"
    >
      <div
          id="SignIn"
          uk-modal
      >
        <div class="uk-modal-dialog uk-modal-body">
          <h2 class="uk-modal-title">
            Sign In
          </h2>
          <hr>
          <SignIn />
        </div>
      </div>

      <div
          id="Search"
          uk-modal
      >
        <div class="uk-modal-dialog uk-modal-body">
          <h2 class="uk-modal-title">
            Search
          </h2>
          <hr>
          <Search />
        </div>
      </div>

      <div
        id="Upload"
        uk-modal
      >
        <div class="uk-modal-dialog uk-modal-body">
          <h2 class="uk-modal-title">
            Upload
          </h2>
          <hr>
          <Upload />
        </div>
      </div>

      <div
        id="Settings"
        uk-modal
      >
        <div class="uk-modal-dialog uk-modal-body">
          <h2 class="uk-modal-title">
            Settings
          </h2>
          <hr>
          <Settings />
        </div>
      </div>

      <div
          id="ImageDetails"
          uk-modal
      >
        <div
            v-if="selectedImage"
            class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical"
        >
          <div>
            <vImageDetails :image="selectedImage" />
          </div>
        </div>
      </div>

      <router-view v-show="user" />

      <div
          v-show="!user"
          id="appHeading"
          class="uk-height-1-1"
      >
        <div class="title">
            <h1>Møns Tugthus</h1>
            <p>Login and enter the laboratory</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Navigation                       from './components/Navigation'
import UIkit                            from 'uikit'
import SignIn                           from './components/SignIn'
import Settings                         from './components/settings/Settings'
import Search                           from './components/Search'
import Upload                           from './components/Upload'
import vImageDetails                    from "@/components/gallery/vImageDetails"
import { LIST_DEFAULT_VALUES, SIGNOUT } from "@/store/operations"

export default {
  name: 'App',
  components: {
    Navigation,
    SignIn,
    Settings,
    Search,
    Upload,
    vImageDetails,
  },
  data() {
    return {
      modalImageDetails: null,
      modalSearch: null,
      modalSignIn: null,
      modalSettings: null,
      modalUpload: null,
    }
  },
  computed: {
    selectedImage() { return this.$store.state.selectedImage },
    backgroundStyle() {
      const color = this.$store.state.showSearchResults ? "#ffff00" : "#e3e3e3"
      return {
        backgroundColor: color + '!important'
      }
    },
    isAdmin() {
      return this.$store.state.user?.isAdmin
    },
    user() {
      return this.$store.state.user
    },
  },
  watch: {
    '$store.state.showModal'(val) {
      this.toggleModalDialog(val)
    },
    '$store.state.selectedImage'(val) {
      this.toggleModalDialog('ImageDetails', val ?  'show' : 'hide')
    }
  },
  mounted() {
    this.modalImageDetails = UIkit.modal(document.getElementById('ImageDetails'))
    this.modalSearch = UIkit.modal(document.getElementById('Search'))
    this.modalSignIn = UIkit.modal(document.getElementById('SignIn'))
    this.modalSettings = UIkit.modal(document.getElementById('Settings'))
    this.modalUpload = UIkit.modal(document.getElementById('Upload'))
    this.$store.dispatch(LIST_DEFAULT_VALUES)
  },
  methods: {
    handleNavigationSelect(selection) {
      if (selection === 'SignOut') this.signOut()
      else this.toggleModalDialog(selection)
    },
    signOut() {
      this.$store.commit(SIGNOUT)
    },
    hideAllModals() {
      console.log(22, 'hiding all modals')

      this.modalImageDetails.hide()
      this.modalSearch.hide()
      this.modalSignIn.hide()
      this.modalSettings.hide()
      this.modalUpload.hide()
    },
    toggleModalDialog(requestedModal, method) {
      if (!requestedModal) this.hideAllModals()
      else {
        const modal = this['modal' + requestedModal]
        method = method || 'show'
        modal[method]()
      }
    }
  }
}
</script>

<style>
body {
  position: relative;
  height: 100vh;
}
#root {
  min-width: 100vw;
  height: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#brandIcon {
  height: 50px;
  width: 50px;
  background: url('../public/Logo.png') no-repeat 0 20px;
  background-size: contain;
  margin-bottom: 1rem;
}

#nav {
  text-align: center;
  height: 100vh;
  background-color: #002546;
}

#nav .uk-icon {
  font-weight: bold;
  height: 2rem;
  width: 2rem;
  color: #ccc!important;
}

#nav .uk-icon.is-active {
}
#nav .uk-icon * {
  width: 2rem;
}

#appHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: url('https://moenstugthus.dk/transkribus/1737_samlet-0001.jpg')
}
#appHeading .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  position: absolute;
  top: 30%;
}
.highlight {
  background-color: #bbe19c;
}
</style>
