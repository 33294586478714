<template>
  <div id="nav">
    <div id="brandIcon" @click="goHome"/>
    <ul
        v-show="user"
        class="uk-iconnav uk-iconnav-vertical"
    >

<!--      <li-->
<!--          @click="onSelect('Upload')"-->
<!--          :class="{'is-active': activeModal === 'Upload'}"-->
<!--      >-->
<!--        <div uk-icon="cloud-upload" />-->
<!--      </li>-->
      <li
          @click="$router.push('/')"
      >
        <div uk-icon="folder" />
      </li>
      <li
          @click="$router.push('/search')"
      >
        <div uk-icon="search" />
      </li>
      <li
          v-if="isAdmin"
          @click="onSelect('Settings')"
          :class="{'is-active': activeModal === 'Settings'}"
      >
        <div uk-icon="settings" />
      </li>
      <li @click="onSelect('SignOut')">
        <div uk-icon="sign-out" />
      </li>
    </ul>
    <ul
        v-show="!user"
        class="uk-iconnav uk-iconnav-vertical"
    >
      <li
          @click="onSelect('SignIn')"
          :class="{'is-active': activeModal === 'SignIn'}"
      >
        <div uk-icon="sign-in" />
      </li>
    </ul>
  </div>
</template>

<script>
import {useRouter} from "vue-router";

export default {
  name: "Navigation",
  emits: ['select'],
  setup () {
    const router = useRouter()
    const goHome = () => router.push('/')
    return {
      goHome,
    }
  },
  computed: {
    activeModal() {
      return this.$store.state.showModal
    },
    isAdmin() {
      return this.$store.state.user?.isAdmin
    },
    user() {
      return this.$store.state.user
    },
  },
  methods: {
    onSelect(route) {
      this.$emit('select', route)
    },
  }
}
</script>

<style scoped>
  .uk-iconnav {
    padding-top: 2rem;
  }
</style>
