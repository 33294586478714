<template>
    <div class="uk-section">
      <div class="collection uk-container">
      <h1>
        Møns Tugthus Samling
      </h1>

      <PaginatedList
          itemLabel="document"
          :itemCount="itemCount"
          listUrl="collection"
          :pageSize="pageSize"
          :page="currentPage"
      />

      <div v-if="documents">
        <div
            v-for="doc in documents"
            :key="doc"
            @click="loadDocument(doc.name)"
        >
          {{ doc.name }} ({{ doc.pages }} pages)
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {useStore} from 'vuex'
import {computed} from "vue";
import {LIST_DOCUMENTS} from "@/store/operations";
import {useRouter} from "vue-router";
import PaginatedList from "@/components/paginatedList/PaginatedList";
import {usePaginatedList} from "@/components/paginatedList/use/usePaginatedList";

export default {
  name: "Collection",
  components: {
    PaginatedList,
  },
  props: {
    page: {
      type: String,
      default: '0'
    }
  },
  setup(props) {
    const router = useRouter()
    const store = useStore()
    const {
      currentPage,
      getItemPage,
      pageSize,
    } = usePaginatedList(props)

    pageSize.value = 30

    store.dispatch(LIST_DOCUMENTS)

    const documents = computed(() => {
      const sortedList = Object.entries(store.state.documents).sort((a, b) => a[0] > b[0])
      return getItemPage(sortedList, (key, value) => {
        return {
          name: key,
          pages: value
        }
      })
    })
    const itemCount = computed(() => Object.entries(store.state.documents)?.length ?? 0)
    const loadDocument = name => router.push({name: 'Document', params: {id: name}})
    return {
      currentPage,
      itemCount,
      pageSize,
      documents,
      loadDocument
    }
  }
}
</script>

<style scoped>

</style>
