import {
  generateId,
  getAPIUrl,
  getRequest,
} from "@/store/requests/lib/requestTypes"

export const loadImagePageRequest = () => {
  const url = getImageActionUrl()
  return getRequest({url})
}
export const getImageDataRequest = image => {
  const imageId = generateId(image.src)
  const url = getImageActionUrl(imageId)
  return getRequest({url})
}
export const deleteImageDataRequest = ({image, pass}) => {
  const imageId = generateId(image.src)
  const url = getImageActionUrl(imageId)
  return getRequest({
    method: 'DELETE',
    url,
    data: {pass}
  })
}
export const getImagesDataRequest = () => {
  const url = getImageActionUrl('data')
  return getRequest({url})
}
export const patchImageDataRequest = image => {
  const imageId = generateId(image.src)
  const url = getImageActionUrl(imageId)
  return getRequest({
    method: 'PATCH',
    url,
    data: image
  })
}

function getImageActionUrl(part) {
  const baseUrl = getAPIUrl()
  return `${baseUrl}/images${part ? ('/' + part) : ''}`
}
