export default function (list, filter, ignoredAttributes = []) {
  return list.filter(item => {
    let pass = false
    Object.entries(filter)
      .forEach(([attributeName, filterValue]) => {
        pass |= matchesAttribute(item, attributeName, filterValue)
      })
    if (!pass && filter.freetext) {
      pass = matchesAnyAttribute(item, filter.freetext, ignoredAttributes)
    }
    return pass
  })
}
function matchesAnyAttribute(item, filterValue, ignoredAttributes)  {
  const attributeValues = Object.entries(item)
    .filter(([name]) => !ignoredAttributes.includes(name))
    // eslint-disable-next-line no-unused-vars
    .map(([name, value]) => value)

  let pass =  false
  for (let i = 0; i < attributeValues.length; i++) {
    const value = attributeValues[i]
    pass = value !== null &&
      (
        matchesByText(value, filterValue) ||
        matchesByRegExp(value, filterValue)
      )
    if (pass) break
  }
  return pass
}

function matchesAttribute(item, attributeName, filterValue) {
  let value = item[attributeName] ?? null

  return value !== null && (
    matchesByText(value, filterValue) ||
    matchesByRegExp(value, filterValue)
  )
}

function matchesByText(value, filter) {
  return value.includes(filter)
}

function matchesByRegExp(value, filter) {
  const rx = new RegExp(filter, 'mui')
  return rx.test(value)
}
