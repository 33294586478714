export const LOAD_IMAGES = 'LOAD_IMAGES'
export const GET_IMAGES_DATA = 'GET_IMAGES_DATA'
export const LOAD_IMAGE_DATA = 'LOAD_IMAGE_DATA'
export const PATCH_IMAGE_DATA = 'PATCH_IMAGE_DATA'
export const DELETE_IMAGE_AND_DATA = 'DELETE_IMAGE_AND_DATA'

export const LIST_DOCUMENTS = 'LIST_DOCUMENTS'
export const GET_DOCUMENT= 'GET_DOCUMENT'
export const SEARCH_COLLECTION = 'SEARCH_COLLECTION'
export const DOWNLOAD_RESULTS = 'DOWNLOAD_RESULTS'
export const PATCH_TRANSCRIPT = 'PATCH_TRANSCRIPT'
export const LIST_TRANSCRIPT_FIELDS = 'LIST_TRANSCRIPT_FIELDS'

export const LIST_META_DATA_FIELDS = 'LIST_META_DATA_FIELDS'
export const ADD_META_DATA_FIELD = 'ADD_META_DATA_FIELD'
export const DELETE_META_DATA_FIELD = 'DELETE_META_DATA_FIELD'

export const LIST_DEFAULT_VALUES = 'LIST_DEFAULT_VALUES'
export const SET_DEFAULT_VALUE = 'SET_DEFAULT_VALUE'

export const SEARCH_IMAGE = 'SEARCH_IMAGE'
export const SWITCH_TO_SEARCH = 'SWITCH_TO_SEARCH'
export const SWITCH_TO_CATALOG = 'SWITCH_TO_CATALOG'

export const SET_MODAL = 'SET_MODAL'

export const LOGIN = 'LOGIN'
export const SIGNOUT = 'SIGNOUT'
export const REGISTER = 'REGISTER'
export const LOAD_WHITELIST = 'LOAD_WHITELIST'
export const WHITELIST_USER = 'WHITELIST_USER'
export const PROMOTE_USER = 'PROMOTE_USER'
export const DEMOTE_USER = 'DEMOTE_USER'
export const REMOVE_USER = 'REMOVE_USER'
