<template>
  <div class="uk-form-horizontal">
    <ul
        v-if="isAdmin"
        uk-tab
    >
      <li
          v-if="isAdmin"
          :class="{'uk-active': openTab === 0}"
          @click="openTab = 0"
      >
        <a href="#">Users</a>
      </li>
      <li
          v-if="isAdmin"
          :class="{'uk-active': openTab === 1}"
          @click="openTab = 1"
     >
       <a href="#">Global Meta Data Fields</a>
     </li>
    </ul>
    <Whitelist v-if="isAdmin && openTab === 0"></Whitelist>
    <GlobalMetaDataSettings v-if="isAdmin && openTab === 1"></GlobalMetaDataSettings>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import Whitelist       from './Whitelist'
import GlobalMetaDataSettings from "@/components/settings/GlobalMetaDataSettings";

export default {
  name: "Settings",
  components: {
    GlobalMetaDataSettings,
    Whitelist,
  },
  setup () {
    const store = useStore()
    const openTab = ref(0)
    const user = computed(() => store.state.user)
    const isAdmin = computed(() => store.state.user?.isAdmin)
    const isEditor = computed(() => store.state.user?.isEditor)

    return {
      openTab,
      isAdmin,
      isEditor,
      user
    }
  }
}
</script>

<style scoped>

</style>
