import {computed, ref} from "vue";

export const usePaginatedList = props => {
  const currentPage = computed(() => Number.isInteger(parseInt(props.page)) ? parseInt(props.page) : 0)
  const pageSize = ref(25)
  const pageOffset = computed(() => currentPage.value * pageSize.value)
  const items = ref([])
  const itemCount = computed(() => items.value.length)
  const getItemPage = (items, itemGenerator) => {
    items.value = items
    return items
        .reduce((list, [key, value]) => {
          let item = ''
          const index = list.length
          const pageEnd = pageOffset.value + pageSize.value
          const isInCurrentPage = pageOffset.value <= index && index < pageEnd
          if (isInCurrentPage) item = itemGenerator(key, value)
          list.push(item)
          return list
        }, [])
        .filter(item => item)
  }
  return {
    currentPage,
    getItemPage,
    itemCount,
    pageOffset,
    pageSize,
  }
}
