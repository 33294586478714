<template>
  <div class="paginatedList">
    <Pagination
        :label="itemLabel"
        :itemCount="itemCount"
        :listUrl="listUrl"
        :pageSize="pageSize"
        :page="currentPage"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {usePaginatedList} from "@/components/paginatedList/use/usePaginatedList";

export default {
  name: "PaginatedList",
  components: {
    Pagination,
  },
  props: {
    itemLabel: {
      type: String,
      default: 'item'
    },
    itemCount: {
      type: Number,
      default: 0
    },
    listUrl: {
      type: String,
      required: true
    },
    pageSize: {
      type: Number,
      default: 25
    },
    page: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const { currentPage } = usePaginatedList(props)

    return {
      currentPage,
    }
  }
}
</script>

<style scoped>

</style>
