<template>
  <div class="uk-form-stacked">
    <!-- volume -->
    <div class="uk-form-controls">
      <select
          v-model="selectedVolume"
          class="uk-select"
      >
        <option :value="null">Any volume</option>
        <option
            v-for="v in volumes"
            :key="v"
            :value="v"
        >{{ v }}</option>
      </select>
    </div>
    <!-- priority -->
    <div class="uk-form-controls uk-margin">
      <select
          v-model="selectedMediaType"
          class="uk-select"
      >
        <option :value="null">Any Priority</option>
        <option
            v-for="p in priorities"
            :key="p"
            :value="p"
        >{{ p }}</option>
      </select>
    </div>
    <!-- motive -->
    <div class="uk-form-controls uk-margin">
      <label class="uk-form-label" for="form-horizontal-text">Motive: </label>
      <input
          v-model="selectedMotive"
          class="uk-input" placeholder="motive"
          type="text"
      >
    </div>
    <!-- media type -->
    <div class="uk-form-controls uk-margin">
      <label class="uk-form-label" for="form-horizontal-text">Media type: </label>
      <select
        v-model="selectedMediaType"
        class="uk-select"
      >
        <option :value="null">Any media type</option>
        <option
            v-for="v in mediaTypes"
            :key="v"
            :value="v"
        >{{ v }}</option>
      </select>
    </div>
    <!-- year inputs -->
    <label class="uk-form-label" for="form-horizontal-text">Year (start, end): </label>
    <div class="uk-column-1-2">
      <div class="uk-width-1-1">
        <input
            v-model="selectedStartYear"
            class="uk-input" placeholder="start year"
            type="text"
        >
      </div>
      <div class="uk-width-1-1">
        <input
            v-model="selectedEndYear"
            class="uk-input"
            type="text"
            placeholder="end year"
        >
      </div>
    </div>
    <!-- free text -->
    <div class="uk-form-controls uk-margin">
      <label class="uk-form-label" for="form-horizontal-text">Freetext query: </label>
      <input
          v-model="selectedFreeText"
          type="text"
          class="uk-input"
          placeholder="Freetext query"
      >
    </div>
    <div class="uk-column-1-2">
      <div class="uk-width-1-1">
        <button
            class="uk-button"
            @click="searchImages"
        >Search</button>
      </div>
      <div class="uk-width-1-1 uk-text-right">
        <button
            class="uk-button uk-button-default"
            @click="reset"
        >Reset</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_IMAGES_DATA, SEARCH_IMAGE, SWITCH_TO_CATALOG } from "@/store/operations"

export default {
  name: "Search",
  data() {
    return {
      selectedVolume: null,
      selectedPriority: null,
      selectedMotive: null,
      selectedMediaType: null,
      selectedStartYear: null,
      selectedEndYear: null,
      selectedFreeText: null,
    }
  },
  computed: {
    defaultVolume() { return this.$store.state.defaultVolume },
    volumes() { return this.$store.state.volumes },
    priorities() { return this.$store.state.priorities },
    mediaTypes() { return this.$store.state.mediaTypes },
  },
  mounted() {
    this.$store.dispatch(GET_IMAGES_DATA)
  },
  methods: {
    searchImages() {
      const query = {}
      if (this.selectedVolume) query.volume = this.selectedVolume
      if (this.selectedPriority) query.priority = this.selectedPriority
      if (this.selectedMotive) query.motive = this.selectedMotive
      if (this.selectedMediaType) query.mediaType = this.selectedMediaType
      if (this.selectedStartYear) query.startYear = this.selectedStartYear
      if (this.selectedEndYear) query.endYear = this.selectedEndYear
      if (this.selectedFreeText) query.freetext = this.selectedFreeText
      this.$store.dispatch(SEARCH_IMAGE, query)
    },
    reset() {
      console.log(0, this.$store.state.defaultVolume)

      this.selectedVolume = this.$store.state.defaultVolume ?? null
      this.selectedPriority = null
      this.selectedMotive = null
      this.selectedMediaType = null
      this.selectedStartYear = null
      this.selectedEndYear = null
      this.selectedFreeText = null
      this.$store.commit(SWITCH_TO_CATALOG)
    }
  }
}
</script>

<style scoped>

</style>
