import axios from "axios"
import {getBaseUrl} from "@/plugins/lib/getBaseUrl";

const globalAxios = axios.create({
  baseURL: getBaseUrl(),
})

globalAxios.interceptors.request.use(
    // config => {
    //   let accessToken = localStorage.getItem('token')
    //   if (accessToken) {
    //     config.headers = Object.assign({
    //       Authorization: `Bearer ${accessToken}`
    //     }, config.headers)
    //   }
    //   return config
    // },
    error => {
      return Promise.reject(error)
    }
)

export default globalAxios
