import {createApp} from "vue"
import asyncomputed from 'vue3-async-computed';
import App           from "./App.vue"
import router        from "./router"
import store         from "./store"
import Uikit         from 'uikit'
import Icons         from 'uikit/dist/js/uikit-icons.min'
import globalAxios from './plugins/axios'
import 'uikit/dist/css/uikit.min.css'

Uikit.use(Icons)

createApp(App)
  .use(store)
  .use(router)
  .use(asyncomputed)
  .provide('$axios', globalAxios)
  .mount("#app")

