import {
  getRequest,
  getAPIUrl,
} from "@/store/requests/lib/requestTypes";

export const getUserRequest = () => {
  const url = getUserActionUrl()
  return getRequest({
    method: 'GET',
    url,
  })
}
export const postUserRequest = (email, payload) => {
  const url = getUserActionUrl()
  return getRequest({
    method: 'POST',
    url,
    data: payload
  })
}
export const putUserRequest = (email, modus) => {
  let url = getUserActionUrl(encodeURIComponent(email))
  if (modus) url += '/' + modus
  return getRequest({
    method: 'PUT',
    url,
  })
}
export const deleteUserRequest = email => {
  const url = getUserActionUrl(encodeURIComponent(email))
  return getRequest({
    method: 'DELETE',
    url,
  })
}

function getUserActionUrl(id) {
  const baseUrl = getAPIUrl()
  return `${ baseUrl }/users${ id ? ('/' + id) : '' }`
}
