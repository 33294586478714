import {
  getAPIUrl,
  getRequest,
} from "@/store/requests/lib/requestTypes"

export const loadDefaultValuesRequest = () => {
  const url = getDefaultsActionUrl()
  return getRequest({url})
}
export const putDefaultValueRequest = update => {
  const url = getDefaultsActionUrl(update.attributeName)
  return getRequest({
    method: 'PUT',
    url,
    data: update
  })
}

export function getDefaultsActionUrl(attributeName) {
  const baseUrl = getAPIUrl()
  return `${ baseUrl }/defaults${ attributeName ? ('/' + attributeName) : '' }`
}
