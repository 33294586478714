<template>
  <div class="details uk-card uk-card-body">
    <div
        v-if="image.error"
        class="uk-alert-danger"
    >{{ image.error }}</div>
    <div class="uk-grid uk-child-width-1-2" uk-grid>
      <div>
        <form
            class="uk-form-stacked"
            v-on:submit.prevent=""
            @keydown.ctrl.shift.delete="deleteImage"
        >
          <fieldset class="uk-fieldset">
            <!-- image name -->
            <em>{{ image.name }}</em>
            <!-- volume, priority -->
            <div class="uk-grid uk-grid-small uk-child-width-1-2" uk-grid>
              <div>
                <select
                    :value="selectedVolume"
                    @change="setAttribute($event, 'volume')"
                >
                  <option disabled :value="null">Please select a volume</option>
                  <option
                      v-for="v in volumes"
                      :key="v"
                      :value="v"
                  >{{ v }}
                  </option>
                </select>
              </div>
              <div>
                <select
                    :value="selectedPriority"
                    @change="setAttribute($event, 'priority')"
                >
                  <option disabled :value="null">Please select a priority</option>
                  <option
                      v-for="p in priorities"
                      :key="p"
                      :value="p"
                  >{{ p }}
                  </option>
                </select>
              </div>
            </div>
            <!-- motive, locality, theme -->
            <div>
              <div class="uk-form-label" for="motive">Motive</div>
              <textarea
                  name="motive"
                  cols="30" rows="3"
                  class="uk-width-1-1"
                  :value="image.motive"
                  @change="setAttribute($event, 'motive')"
              ></textarea>
            </div>
            <div>
              <div class="uk-form-label" for="motive">Locality</div>
              <textarea
                  name="motive"
                  cols="30"
                  rows="3"
                  class="uk-width-1-1"
                  :value="image.locality"
                  @change="setAttribute($event, 'locality')"
              ></textarea>
            </div>
            <div>
              <div class="uk-form-label" for="motive">Theme</div>
              <textarea
                  name="motive"
                  cols="30"
                  rows="3"
                  class="uk-width-1-1"
                  :value="image.theme"
                  @change="setAttribute($event, 'theme')"
              ></textarea>
            </div>
            <!-- year inputs -->
            <div class="uk-column-1-2">
              <div class="uk-width-1-1">
                <input
                    class="uk-input" placeholder="start year"
                    type="text"
                    :value="image.startYear"
                    @change="setAttribute($event, 'startYear')"
                >
              </div>
              <div class="uk-width-1-1">
                <input
                    type="text"
                    class="uk-input"
                    placeholder="end year"
                    :value="image.endYear"
                    @change="setAttribute($event, 'endYear')"
                >
              </div>
            </div>
            <!-- chapter, origin, reference -->
            <div>
              <div class="uk-form-label" for="motive">Chapter</div>
              <textarea
                  name="motive"
                  cols="30"
                  rows="3"
                  class="uk-width-1-1"
                  :value="image.chapter"
                  @change="setAttribute($event, 'chapter')"
              ></textarea>
            </div>
            <div>
              <div class="uk-form-label" for="motive">Origin / Artist</div>
              <textarea
                  name="motive"
                  cols="30"
                  rows="3"
                  class="uk-width-1-1"
                  :value="image.origin"
                  @change="setAttribute($event, 'origin')"
              ></textarea>
            </div>
            <div>
              <div class="uk-form-label" for="motive">Discovered at / Reference</div>
              <textarea
                  name="motive"
                  cols="30"
                  rows="3"
                  class="uk-width-1-1"
                  :value="image.reference"
                  @change="setAttribute($event, 'reference')"
              ></textarea>
            </div>
            <template v-if="!orientationIsLandscape">
              <div>
                <div class="uk-form-label" for="motive">Provenience</div>
                <textarea
                    name="motive"
                    cols="30"
                    rows="3"
                    class="uk-width-1-1"
                    :value="image.provenience"
                    @change="setAttribute($event, 'provenience')"
                ></textarea>
              </div>
              <!-- selects -->
              <div class="uk-grid uk-grid-small uk-child-width-1-2" uk-grid>
                <div>
                  <select
                      :value="selectedState"
                      @change="setAttribute($event, 'state')"
                  >
                    <option disabled :value="null">Select a state</option>
                    <option
                        v-for="s in states"
                        :key="s"
                        :value="s"
                    >{{ s }}
                    </option>
                  </select>
                </div>
                <div>
                  <select
                      :value="selectedMediaType"
                      @change="setAttribute($event, 'mediaType')"
                  >
                    <option disabled :value="null">Select a media type</option>
                    <option
                        v-for="p in mediaTypes"
                        :key="p"
                        :value="p"
                    >{{ p }}
                    </option>
                  </select>
                </div>
              </div>
            </template>
          </fieldset>
        </form>
      </div>

      <div>
        <form
            class="uk-form-stacked"
            v-on:submit.prevent=""
        >
          <fieldset class="uk-fieldset">
            <!-- image-->
            <div>
              <a :href="image.src" target="_blank">
                <img :src="image.src" :alt="image.name" />
              </a>
            </div>
            <template v-if="orientationIsLandscape">
              <div>
                <div class="uk-form-label" for="motive">Provenience</div>
                <textarea
                    name="motive"
                    cols="30"
                    rows="3"
                    class="uk-width-1-1"
                    :value="image.provenience"
                    @change="setAttribute($event, 'provenience')"
                ></textarea>
              </div>
              <!-- selects -->
              <div class="uk-grid uk-grid-small uk-child-width-1-2" uk-grid>
                <div>
                  <select
                      :value="selectedState"
                      @change="setAttribute($event, 'state')"
                  >
                    <option disabled :value="null">Select a state</option>
                    <option
                        v-for="s in states"
                        :key="s"
                        :value="s"
                    >{{ s }}
                    </option>
                  </select>
                </div>
                <div>
                  <select
                      :value="selectedMediaType"
                      @change="setAttribute($event, 'mediaType')"
                  >
                    <option disabled :value="null">Select media type</option>
                    <option
                        v-for="p in mediaTypes"
                        :key="p"
                        :value="p"
                    >{{ p }}
                    </option>
                  </select>
                </div>
              </div>
            </template>
            <!-- hyperlink -->
            <div class="uk-width-1-1">
              <input
                  type="text"
                  class="uk-input"
                  placeholder="hyperlink"
                  :value="image.hyperlink"
                  @change="setAttribute($event, 'hyperlink')"
              >
            </div>
            <!-- textareas -->
            <div class="uk-form-label" for="motive">Image Text</div>
            <textarea
                name="motive"
                cols="30"
                rows="3"
                class="uk-width-1-1"
                :value="image.text"
                @change="setAttribute($event, 'text')"
            ></textarea>
            <div class="uk-form-label" for="motive">Comments</div>
            <textarea
                name="motive"
                cols="30"
                rows="3"
                class="uk-width-1-1"
                :value="image.comments"
                @change="setAttribute($event, 'comments')"
            ></textarea>
            <button
                v-if="isAdmin"
                class="uk-button uk-button-danger"
                @click="deleteImage"
            >Delete Image and Data</button>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { DELETE_IMAGE_AND_DATA, LOAD_IMAGES, PATCH_IMAGE_DATA } from '@/store/operations'

export default {
  name: "vImageDetails",
  computed: {
    image() { return this.$store.state.selectedImage },
    isAdmin() {
      return this.$store.state.user?.isAdmin
    },
    mediaTypes() { return this.$store.state.mediaTypes },
    orientationIsLandscape() { return this.image.width > this.image.height },
    priorities() { return this.$store.state.priorities },
    selectedVolume: {
      get() { return this.image.volume },
      set(value) {
        this.syncImageData({
          src: this.image.src,
          attributeName: 'volume',
          value
        })
      }
    },
    selectedPriority: {
      get() { return this.image.priority },
      set(value) {
        this.syncImageData({
          src: this.image.src,
          attributeName: 'priority',
          value
        })
      }
    },
    selectedMediaType: {
      get() { return this.image.priority },
      set(value) {
        this.syncImageData({
          src: this.image.src,
          attributeName: 'mediaType',
          value
        })
      }
    },
    selectedState: {
      get() { return this.image.priority },
      set(value) {
        this.syncImageData({
          src: this.image.src,
          attributeName: 'state',
          value
        })
      }
    },
    states() { return this.$store.state.states },
    volumes() { return this.$store.state.volumes },
  },
  mounted() {
    this.loadSelectOptionStates()
  },
  methods: {
    deleteImage() {
      const confirmation = prompt('You are about to delete this image and its data. Enter "delete" to confirm')
      if  (confirmation.trim() === 'delete') {
        this.$store.dispatch(DELETE_IMAGE_AND_DATA, {
          image: this.$store.state.selectedImage,
        })
        .then(() => {
          this.$store.dispatch(LOAD_IMAGES)
        })
      }
    },
    loadSelectOptionStates() {
      this.selectedVolume = this.image.volume ?? this.$store.state.defaultVolume ?? null
      this.selectedPriority = this.image.priority ?? null
      this.selectedState = this.image.state ?? null
      this.selectedMediaType = this.image.mediaType ?? null
    },
    setAttribute(event, attributeName) {
      const value = event.target.value
      this.syncImageData({
        src: this.image.src,
        attributeName,
        value
      })
    },
    syncImageData(imageData) {
      this.$store.dispatch(PATCH_IMAGE_DATA, imageData)
    },
  }
}
</script>

<style scoped>

</style>
