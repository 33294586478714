
<template>
  <div class="globalMetaDataSettings">
    <ul class="uk-list">
      <li
          v-for="name in globalTagNames"
          :key="name"
          class="uk-form-stacked"
      >
        <div class="user" uk-grid>
          <span class="uk-width-1-2">{{ name }}</span>
          <a @click="deleteGlobalTag(name)">unlist field</a>
        </div>
      </li>
      <li v-if="!globalTagNames?.length">
        no global meta data fields defined yet...
      </li>
    </ul>
    <hr>
    <div uk-grid class="uk-grid-collapse">
      <input
          ref="newFieldName"
          type="text"
          class="uk-input uk-width-2-3"
          placeholder="Field name to add"
      />
      <button
          title="coming soon..."
          class="uk-button uk-button-primary uk-width-1-3"
          @click="addField"
      >Add Field</button>
    </div>

  </div>
</template>

<script>
import {useGlobalMetaDataTags} from "@/use/useGlobalMetaDataTags";
import {ref} from "vue";

export default {
  name: "GlobalMetaDataSettings",
  setup() {
    const { globalTagNames, listGlobalTags, addGlobalTag, deleteGlobalTag } = useGlobalMetaDataTags()
    listGlobalTags()
    const newFieldName = ref(null)
    const addField = () => {
      const name = newFieldName.value.value
      if (!globalTagNames.value.includes(name)) {
        addGlobalTag(name)
        .then(() => newFieldName.value.value = '')
      }
    }
    return {
      addField,
      deleteGlobalTag,
      newFieldName,
      globalTagNames,
    }
  }
}
</script>

<style scoped>

</style>
