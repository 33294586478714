<template>
  <div class="uk-alert uk-width-1-1@l uk-alert-au">
    <ul class="uk-pagination uk-margin uk-flex-center">
      <li>
        <a
            :disabled="!previousPageRoute"
            :class="{disabled: !previousPageRoute}"
            @click="openPreviousPage"><span uk-pagination-previous></span></a>
      </li>
      <li v-if="firstBlock">
        <a @click="openFirstPage">1</a>
      </li>
      <li
          v-if="padFirstBlock"
          class="uk-disabled"
      ><span>...</span></li>
      <li
          v-for="number in activeBlock"
          :key="number"
      >
        <a @click="openPageByIndex(number)">{{ number }}</a>
      </li>
      <li
          v-if="padLastBlock"
          class="uk-disabled"
      ><span>...</span></li>
      <li v-if="lastBlock">
        <a @click="openLastPage">{{ pageCount }}</a>
      </li>
      <li>
        <a
            :disabled="nextPageRoute"
            :class="{disabled: !nextPageRoute}"
            @click="openNextPage"><span uk-pagination-next></span></a>
      </li>
      <li class="label"><span class="summary italic">( {{ summary }} )</span></li>
    </ul>
  </div>
</template>

<script>
import range from "../../lib/range"
import {computed} from "vue";
import {useRouter} from "vue-router";

export default {
  name: "Pagination",
  props: {
    label: {
      type: String,
      default: 'file'
    },
    listUrl: {
      type: String,
      required: true
    },
    itemCount: { // 1-based
      type: Number,
      required: true
    },
    page: { // 0-based
      default: 0
    },
    pageSize: { // 1-based
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const router = useRouter()
    const activeBlock = computed(() => {
      const start = Math.max(1, props.page - activePagePaddingCount.value)
      const end = Math.min(pageCount.value, props.page + activePagePaddingCount.value)
      return range(start, end)
    })
    const activePagePaddingCount = computed(() => Math.floor(maxNumberBlockSize / 2))
    const firstBlock = computed(() => !activeBlock.value.includes(1))
    const lastBlock = computed(() => pageCount.value > 1 && !activeBlock.value.includes(pageCount.value))
    const maxNumberBlockSize = 7
    const padFirstBlock = computed(() => activeBlock.value[0] > 2)
    const padLastBlock = computed(() => lastBlock.value)

    const pageCount = computed(() => Math.ceil(props.itemCount / props.pageSize))
    const activePage = computed(() => Number.isInteger(parseInt(props.page)) ? parseInt(props.page) : 0)
    const previousPage = computed(() => activePage.value > 0 ? activePage.value - 1 : activePage.value)
    const nextPage = computed(() => activePage.value < (pageCount.value - 1) ? activePage.value + 1 : activePage.value)
    const previousPageRoute = computed(() => {
      let previousPageRoute = ''
      if ( activePage.value > 0 ) {
        previousPageRoute = pageRoute(previousPage.value)
      }
      return previousPageRoute
    })
    const nextPageRoute = computed(() => {
      let nextPageRoute = ''
      if (activePage.value < (pageCount.value - 1)) nextPageRoute = pageRoute(nextPage.value)
      return nextPageRoute
    })

    const summary = computed(() => {
      let label = ''
      if (props.itemCount !== undefined) {
        switch (props.itemCount) {
          case 0:
            label = `no ${props.label}s`
            break
          case 1:
            label = `one ${props.label}`
            break
          default:
            label = `${props.itemCount} ${props.label}s`
        }
      }
      return label
    })

    const enforcePrefix = (value, prefix = '/') => {
      value = value.toString()
      if (!value.startsWith(prefix)) value = prefix + value
      return value
    }
    const enforceSuffix = (value, suffix = '/') => {
      value = value.toString()
      if (!value.endsWith(suffix)) value += suffix
      return value
    }
    const wrap = (value, padding) => enforcePrefix(enforceSuffix(value, padding), padding)
    const pageRoute = index => wrap(props.listUrl) + index

    const openFirstPage = () => router.push(props.listUrl)
    const openLastPage = () => router.push(pageRoute(pageCount.value - 1))
    const openPageByIndex = index => router.push(pageRoute(index - 1))
    const openNextPage = () => router.push(nextPageRoute.value)
    const openPreviousPage = () => router.push(previousPageRoute.value)

    return {
      activeBlock,
      firstBlock,
      summary,
      lastBlock,
      maxNumberBlockSize,
      nextPageRoute,
      openFirstPage,
      openLastPage,
      openNextPage,
      openPageByIndex,
      openPreviousPage,
      padFirstBlock,
      padLastBlock,
      pageCount,
      previousPageRoute,
    }
  },
}
</script>

<style scoped>
.uk-alert a,
.uk-alert .summary {
  color: white!important;
}
.uk-alert-au {
  background-color: #002546;
  color: white;
}

.disabled {
  opacity: .5;
}

.italic {
  font-style: italic;
}
</style>
