import {
  getAPIUrl,
  getRequest,
} from "@/store/requests/lib/requestTypes"

const BASE_URL = getAPIUrl()
const getActionUrl = fieldName => {
  let url = BASE_URL + '/metaDataFieldNames/'
  if (fieldName) url += fieldName
  return url
}
export const listGlobalMetaDataFieldNames = () => getRequest({
  url: getActionUrl()
})
export const addGlobalMetaDataFieldName = name => getRequest({
  method: 'POST',
  url: getActionUrl(name),
})
export const deleteGlobalMetaDataFieldName = name => getRequest({
  method: 'DELETE',
  url: getActionUrl(name),
})
