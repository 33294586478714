<template>
  <div class="whitelistSettings">
    <div v-if="showRemoveUserConfirmation" class="uk-alert uk-alert-warning">
      <p>Please confirm the de-whitelisting of the account with the email address {{ selectedEmail }}.</p>
      <div class="spaceBetween">
        <button class="uk-button uk-button-primary" @click="removeUser_step2">Remove User</button>
        <button class="uk-button uk-button-default" @click="showRemoveUserConfirmation = false">Cancel</button>
      </div>
    </div>
    <ul class="uk-list">
      <li
          v-for="user in whitelist"
          :key="user.name"
      >
        <div class="user" uk-grid>
          <span class="uk-width-1-2">{{ user.name }}</span>
          <span style="padding-right: .5rem;">editor: </span>
          <input
              ref="isEditor"
              type="checkbox"
              @click="onToggleIsEditor(user)"
              v-model="user.isEditor"
          >
          <a @click="removeUser_step1(user)">delete</a>
        </div>
      </li>
      <li v-if="!whitelist?.length">No users allowed yet...</li>
    </ul>

    <hr>
      <p>Clear a new user for registration</p>
      <div uk-grid class="uk-grid-collapse">
        <input
          ref="emailToWhitelist"
          type="text"
          class="uk-input uk-width-2-3"
          placeholder="Email address to clear for signing up"
        />
        <button
            class="uk-button uk-button-primary uk-width-1-3"
            @click="whitelistUser"
        >Add user</button>
      </div>
  </div>
</template>

<script>
import { LOAD_WHITELIST, REMOVE_USER, WHITELIST_USER, PROMOTE_USER, DEMOTE_USER } from "@/store/operations"
import { computed, ref } from "@vue/reactivity"
import { onMounted } from "@vue/runtime-core"
import { useStore } from "vuex"

export default {
  name: "Whitelist",
  setup() {
    const store = useStore()
    const whitelist = computed(() => store.state.whitelist)
    const emailToWhitelist = ref(null)
    const isEditor = ref(null)
    const selectedEmail = ref('')
    const showRemoveUserConfirmation = ref(false)
    onMounted(() => store.dispatch(LOAD_WHITELIST))
    const removeUser_step1 = user => {
      selectedEmail.value = user.name
      showRemoveUserConfirmation.value = true
    }
    const removeUser_step2 = () => {
      showRemoveUserConfirmation.value = false
      store.dispatch(REMOVE_USER, selectedEmail.value)
    }
    const whitelistUser = () => {
      const email = emailToWhitelist.value.value
      const userIsIncluded = whitelist.value.find(item => item.name === email)
      if (!userIsIncluded) store.dispatch(WHITELIST_USER, email)
      emailToWhitelist.value.value = ''
    }
    const promoteUser = name => {
      const userIsIncluded = whitelist.value.find(item => item.name === name)
      if (userIsIncluded) store.dispatch(PROMOTE_USER, name)
    }
    const demoteUser = name => {
      const userIsIncluded = whitelist.value.find(item => item.name === name)
      if (userIsIncluded) store.dispatch(DEMOTE_USER, name)
    }
    const onToggleIsEditor = user => {
      if (isEditor.value.checked) promoteUser(user.name)
      else demoteUser(user.name)
    }
    return {
      emailToWhitelist,
      demoteUser,
      isEditor,
      onToggleIsEditor,
      promoteUser,
      removeUser_step1,
      removeUser_step2,
      showRemoveUserConfirmation,
      whitelist,
      whitelistUser,
    }
  },
}
</script>

<style scoped>
.spaceBetween {
  display: flex;
  justify-content: space-between;
}
</style>
