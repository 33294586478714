import {
  ADD_META_DATA_FIELD,
  DELETE_IMAGE_AND_DATA,
  DELETE_META_DATA_FIELD,
  DEMOTE_USER,
  DOWNLOAD_RESULTS,
  GET_DOCUMENT,
  GET_IMAGES_DATA,
  LIST_DEFAULT_VALUES,
  LIST_DOCUMENTS,
  LIST_META_DATA_FIELDS,
  LIST_TRANSCRIPT_FIELDS,
  LOAD_IMAGE_DATA,
  LOAD_IMAGES,
  LOAD_WHITELIST,
  LOGIN,
  PATCH_IMAGE_DATA,
  PATCH_TRANSCRIPT,
  PROMOTE_USER,
  REMOVE_USER,
  SEARCH_COLLECTION,
  SEARCH_IMAGE,
  SET_DEFAULT_VALUE,
  SET_MODAL,
  SIGNOUT,
  SWITCH_TO_CATALOG,
  SWITCH_TO_SEARCH,
  WHITELIST_USER,
} from '@/store/operations'
import filterListOfObjectsByTextMatches from "@/store/lib/filterListOfObjectsByTextMatches"
import filterListOfObjectsByNumericMatches from "@/store/lib/filterListOfObjectsByNumericMatches"

/*
  load images and meta data
  paginate requested page --> displayedFiles
  pagination buttons: change currentPage

 */
export default {

  [LIST_DOCUMENTS](state, documents) {
    state.documents = documents
  },
  [GET_DOCUMENT](state, pages) {
    state.pages = [...pages]
  },
  [SEARCH_COLLECTION](state, results) {
    state.results = results
  },
  [DOWNLOAD_RESULTS]() {
    // handled in component
  },
  [SWITCH_TO_SEARCH](state) {
    state.showSearchResults = true
  },
  [SWITCH_TO_CATALOG](state) {
    state.showSearchResults = false
  },
  [LIST_DEFAULT_VALUES](state, defaults) {
    Object.entries(defaults).forEach(([name, value]) => state[name] = value)
  },
  [LOAD_IMAGES](state, images) {
    state.images = [...images]
  },
  [LOAD_IMAGE_DATA](state, image) {
    state.selectedImage = {...image}
    state.showModal = 'ImageDetails'
  },
  [GET_IMAGES_DATA](state, data) {
    state.imagesData = data
  },
  [PATCH_IMAGE_DATA]() {
    // handled in component
  },
  [PATCH_TRANSCRIPT](state, patched) {
    // apply patch
    const {documentName, imageName} = patched
    const transcriptIndex = state.pages.findIndex(page => page.documentName === documentName && page.imageName === imageName)
    if (transcriptIndex >= 0) state.pages.splice(transcriptIndex, 1, patched)
    state.pages = [...state.pages]
  },
  [LIST_TRANSCRIPT_FIELDS](state, fields) {
    state.transcriptFields = fields
  },
  [LIST_META_DATA_FIELDS](state, globalFieldNames) {
    state.globalMetaDataFieldNames = globalFieldNames
  },
  [ADD_META_DATA_FIELD](state, globalFieldNames) {
    state.globalMetaDataFieldNames = globalFieldNames
  },
  [DELETE_META_DATA_FIELD](state, globalFieldNames) {
    state.globalMetaDataFieldNames = globalFieldNames
  },
  [DELETE_IMAGE_AND_DATA](state, image) {
    state.selectedImage = null
    state.showModal = ''
    const deletedImageDataIndex = state.imagesData.indexOf(image)
    state.imagesData.splice(deletedImageDataIndex, 1)
    const deletedImageIndex = state.images.indexOf(image)
    state.images.splice(deletedImageIndex, 1)
  },
  [SET_DEFAULT_VALUE](state, update) {
    if (update.error) {
      console.log('set default error:', update.error)
    } else state[update.attributeName] = update.value
  },
  [SEARCH_IMAGE](state, query) {
    const allImages = state.imagesData
    const textMatchedImages = filterListOfObjectsByTextMatches(allImages, query, ['path', 'priority', 'mediaType', 'volume'])
    const imagesByNumberMatches = filterListOfObjectsByNumericMatches(allImages, query, ['startYear', 'endYear'])
    state.results = textMatchedImages.concat(imagesByNumberMatches)
  },
  [SET_MODAL](state, dialogName) {
    state.showModal = dialogName
  },
  [LOGIN](state, response) {
    state.showModal = ''
    state.user = response ?? null
  },
  [SIGNOUT](state) {
    state.showModal = ''
    state.user = null
  },
  [LOAD_WHITELIST](state, list) {
    state.whitelist = list
  },
  [WHITELIST_USER](state, email) {
    state.whitelist.push({name: email})
  },
  [PROMOTE_USER](state, user) {
    const userIndex = state.whitelist.findIndex(item => item.name === user.email)
    state.whitelist[userIndex] = {...user}
  },
  [DEMOTE_USER](state, user) {
    const userIndex = state.whitelist.findIndex(item => item.name === user.email)
    state.whitelist[userIndex] = {...user}
  },
  [REMOVE_USER](state, email) {
    const userIndex = state.whitelist.findIndex(item => item.name === email)
    state.whitelist.splice(userIndex, 1)
  }
}
