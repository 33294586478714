<template>
  <form
    class="uk-grid-small"
    uk-grid
    v-on:submit.prevent=""
  >
    <div
        v-if="errors.length"
        class="uk-alert uk-alert-danger"
    >
      {{ errors.join('\n') }}
    </div>
    <div class="uk-width-1-1">
      <div class="uk-margin">
        <div class="uk-inline uk-width-1-1">
          <span
            class="uk-form-icon uk-icon"
            uk-icon="icon: mail"
          />
          <input
              ref="id"
              class="uk-input"
              placeholder="Email address"
              autocomplete="username"
              type="text"
          >
        </div>
      </div>
    </div>
    <div class="uk-width-1-1">
      <div class="uk-margin">
        <div class="uk-inline uk-width-1-1">
          <span
              class="uk-form-icon uk-icon"
              uk-icon="icon: lock"
          />
          <input
              ref="pw"
              class="uk-input"
              type="password"
              placeholder="Passphrase"
              autocomplete="current-password"
          >
        </div>
        <div
            v-if="!showRegisterForm"
            class="uk-margin uk-text-right"
        >
          <a @click="showRegisterForm=true">
            I am not yet registered.
          </a>
        </div>
      </div>
    </div>
    <div
        v-if="showRegisterForm"
        class="uk-width-1-1@l"
    >
      <div class="uk-width-1-1">
        <div class="uk-margin">
        <hr>
          <div class="uk-inline uk-width-1-1">
          <span
              class="uk-form-icon uk-icon"
              uk-icon="icon: mail"
          />
            <input
                ref="id2"
                class="uk-input"
                placeholder="Repeat your email address"
                autocomplete="new username"
                type="text"
            >
          </div>
        </div>
      </div>
      <div class="uk-width-1-1">
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <span
                class="uk-form-icon uk-icon"
                uk-icon="icon: lock"
            />
            <input
                ref="pw2"
                class="uk-input"
                placeholder="Repeat your passphrase"
                type="password"
                autocomplete="new-password"
            >
          </div>
        </div>
      </div>

      <p>
        You need to be whitelisted to register.
      </p>
      <p class="uk-width-1-1 uk-text-right">
        <a @click="showRegisterForm=false">Return to Login</a>
      </p>
    </div>
    <div>
      <hr>
      <div class="uk-button uk-button-primary" @click="signIn">{{ showRegisterForm ? 'Register' : 'Login' }}</div>
    </div>
  </form>
</template>

<script>
import { LOGIN, REGISTER, SET_MODAL } from "@/store/operations"

export default {
  name: "SignIn",
  data() {
    return {
      errors: [],
      showRegisterForm: false,
    }
  },
  methods: {
    login() {
      const credentials = {
        email: this.$refs.id.value,
        passphrase: this.$refs.pw.value
      }

      this.errors = []
      const missingEmail = !credentials.email
      if (missingEmail) this.errors.push('your email is required to log you in')

      const missingPassphrase = !credentials.passphrase
      if (missingPassphrase) this.errors.push('your passphrase is required to log in')

      if (!this.errors.length) {
        return this.$store.dispatch(LOGIN, credentials)
          .then(() => {
            this.errors = []
            this.$store.commit(SET_MODAL, '')
          })
          .catch(err => {
            this.errors = [err.message]
          })
      }
    },
    register() {
      const credentials = {
        email: this.$refs.id.value,
        repeatedEmail: this.$refs.id2.value,
        passphrase: this.$refs.pw.value,
        repeatedPassphrase: this.$refs.pw2.value,
      }
      this.errors = []

      const missingEmail = !credentials.email || !credentials.repeatedEmail
      if (missingEmail) this.errors.push('please write your email into both email fields to sign up')

      const missingPassword = !credentials.passphrase || !credentials.repeatedPassphrase
      if (missingPassword) this.errors.push('write your passphrase into both passphrase fields to sign up')

      const passphrasesAreDifferent = credentials.passphrase !== credentials.repeatedPassphrase
      if (passphrasesAreDifferent) this.errors.push('the passphrases you entered do not match')

      if (!this.errors.length) {
        this.$store.dispatch(REGISTER, {
              email: credentials.email,
              passphrase: credentials.passphrase
            })
            .then(() => {
              this.errors = []
              this.$store.commit(SET_MODAL, '')
            })
            .catch(err => {
              this.errors = [err.message]
            })
      }
    },
    signIn() {
      this.errors = []
      if (this.showRegisterForm) {
        this.register()
      } else {
        this.login()
      }
    },

  }
}
</script>

<style scoped>

</style>
