import {getBaseUrl} from "@/plugins/lib/getBaseUrl";

export function getRequest({ method = 'GET', url = '', data }) {
  const request = {
    method,
    url
  }
  if (data) request.data = data
  return request
}

export function generateId(src) {
  return encodeURIComponent(src.replaceAll('/', '-'))
}

export const getAPIUrl = () => getBaseUrl() + 'api'

