import {getAPIUrl, getRequest} from "@/store/requests/lib/requestTypes";

export const getCollectionActionUrl = part => `${getAPIUrl()}/collection${part ? ('/' + part) : ''}`

export const listDocuments = () => getRequest({url: getCollectionActionUrl()})
export const listTranscriptFieldsRequest = () => getRequest({url: getCollectionActionUrl('fields')})
export const getDocument = id => getRequest({url: getCollectionActionUrl(id)})
export const searchCollection = payload => getRequest({
  method: 'POST',
  url: getCollectionActionUrl('search'),
  data: payload
})
export const downloadResults = payload => getRequest({
  method: 'POST',
  url: getCollectionActionUrl('download'),
  data: payload
})
export const patchTranscriptRequest = (patch) => {
  const url = getCollectionActionUrl(encodeURIComponent(patch.id) + '/' + encodeURIComponent(patch.imageName))
  return getRequest({
    method: 'PATCH',
    url,
    data: {
      attributeName: patch.attributeName,
      value: patch.value
    }
  })
}
