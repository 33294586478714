import { createStore } from "vuex"
import actions         from './actions'
import mutations       from './mutations'

export default createStore({
  state: {
    user: null, //{ name: 'me', isAdmin: true }, //
    currentPage: 0,
    currentPageSize: 30,
    defaultGridHeight: 150,
    defaultGridWidth: 150,
    defaultVolume: 0,
    imagesData: [],
    documents: [],
    pages: [],
    images: [],
    results: [],
    transcriptFields: [],
    globalMetaDataFieldNames: null,
    whitelist: [],
    volumes: [
      'Volume 1',
      'Volume 2',
      'Volume 3',
      'Volume 4',
      'Volume 5',
      'Volume 6',
      'X'
    ],
    priorities: [
      'priority: low',
      'priority: medium',
      'priority: high'
    ],
    states: [
      'Available, print quality',
      'Available, quality uncertain',
      'Not Available'
    ],
    mediaTypes: [
      'Photo B/W',
      'Photo in Color',
      'Oilpaintin',
      'Drawing / Aquarel',
      'Woodcut',
      'Copper Engraving',
      'Title page (book, journal)',
      'Print page (book, journal)',
      'Handwriting',
      'Artefact',
      'Other'
    ],
  },
  mutations,
  actions,
})
