<template>
  <div class="js-upload uk-placeholder uk-text-center">
    <span uk-icon="icon: cloud-upload" />
    <span class="uk-text-middle"> Upload images by dropping them here or </span>
    <div uk-form-custom>
      <input
        type="file"
        multiple
      >
      <span class="uk-link"> by finding them in your filesystem</span>
    </div>
  </div>
  <p
      class="message"
  >
    {{ message }}
  </p>
  <progress
    v-if="showProgress"
    id="js-progressbar"
    ref="progressBar"
    class="uk-progress"
    value="0"
    max="100"
  />
</template>

<script>
import UIkit           from 'uikit'
import { getAPIUrl} from "@/store/requests/lib/requestTypes";
import { LOAD_IMAGES, SET_MODAL } from "@/store/operations"

export default {
  name: "Upload",
  data() {
    return {
      uploadUrl: getAPIUrl() + '/upload',
      showProgress: true,
      message: ''
    }
  },
  mounted() {
    const bar = this.$refs.progressBar
    const store = this.$store
    const setMessage = this.setMessage
    const appendMessage = this.appendMessage
    UIkit.upload('.js-upload', {

      url: this.uploadUrl,
      multiple: true,

      beforeSend: function () {
        console.log('beforeSend', arguments);
      },
      beforeAll: function () {
        console.log('beforeAll', arguments);
      },
      load: function () {
        console.log('load', arguments);
      },
      error: function (err) {
        console.log('error', arguments);
        appendMessage(
        'An error occurred with one or more of your uploads: ' + err,
        )
        store.dispatch(LOAD_IMAGES)
      },
      complete: function () {
        console.log('complete', arguments);
      },
      loadStart: function (e) {
        console.log('loadStart', arguments);

        this.showProgress = true

        bar.max = e.total;
        bar.value = e.loaded;
      },
      progress: function (e) {
        console.log('progress', arguments);

        bar.max = e.total;
        bar.value = e.loaded;
      },
      loadEnd: function (e) {
        console.log('loadEnd', arguments);

        bar.max = e.total;
        bar.value = e.loaded;
      },
      completeAll: function () {
        console.log('completeAll', arguments);

        setTimeout(function () {
          this.showProgress = false
        }, 100);

        setMessage('Upload Completed')
        store.commit(SET_MODAL)
        store.dispatch(LOAD_IMAGES)
      }
    })
  },
  methods: {
    setMessage(msg) {
      this.message = msg
    },
    appendMessage(msg) {
      this.message += '\n' + msg
    }
  }
}
</script>

<style scoped>

</style>
