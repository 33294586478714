import { createRouter, createWebHashHistory } from "vue-router"
import Home                                   from "../views/Home.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/search",
    name: "Search",
    component: () => import(/* webpackChunkName: "transcripts" */ "../components/Transcripts/Search/SearchTranscripts.vue"),
    props: true
  },
  {
    path: "/search/:page",
    name: "SearchPage",
    component: () => import(/* webpackChunkName: "transcripts" */ "../components/Transcripts/Search/SearchTranscripts.vue"),
    props: true
  },
  {
    path: "/collection",
    name: "Collection",
    component: () => import(/* webpackChunkName: "transcripts" */ "../components/Transcripts/Collection.vue"),
  },
  {
    path: "/collection/:page",
    name: "CollectionPage",
    component: () => import(/* webpackChunkName: "transcripts" */ "../components/Transcripts/Collection.vue"),
    props: true
  },
  {
    path: "/documents/:id",
    name: "Document",
    component: () => import(/* webpackChunkName: "transcripts" */ "../components/Transcripts/Document.vue"),
    props: true
  },
  {
    path: "/documents/:id/:page",
    name: "DocumentPage",
    component: () => import(/* webpackChunkName: "transcripts" */ "../components/Transcripts/Document.vue"),
    props: true
  },
  {
    path: "/documents/:id/pages/:page",
    name: "Page",
    component: () => import(/* webpackChunkName: "transcripts" */ "../components/Transcripts/PageListItem.vue"),
    props: true
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
