import {useStore} from "vuex";
import {ADD_META_DATA_FIELD, DELETE_META_DATA_FIELD, LIST_META_DATA_FIELDS} from "@/store/operations";
import {computed} from "vue";

export const useGlobalMetaDataTags = () => {
  const store = useStore()
  const listGlobalTags = () => {
    if (!store.state.globalMetaDataFieldNames) store.dispatch(LIST_META_DATA_FIELDS)
  }
  const addGlobalTag = name => store.dispatch(ADD_META_DATA_FIELD, {name})
  const deleteGlobalTag = name => store.dispatch(DELETE_META_DATA_FIELD, {name})
  const globalTagNames = computed(() => store.state.globalMetaDataFieldNames)
  return {
    addGlobalTag,
    deleteGlobalTag,
    listGlobalTags,
    globalTagNames
  }
}
