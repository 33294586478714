<template>
  <div class="home">
    <Collection v-if="debug || user" />
  </div>
</template>

<script>
import Collection from '../components/Transcripts/Collection'
export default {
  name: "Home",
  components: {
    Collection,
  },
  data ()  {
    return {
      debug: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.user?.isAdmin
    },
    user() {
      return this.$store.state.user
    },
  }
};
</script>
